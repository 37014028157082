import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { device } from 'src/device'
import { SubTitle } from 'src/elements/SubTitle'
import { TitleH2 } from 'src/elements/TitleH2'
import { gql } from 'src/graphql'

const Header = styled.div`
  margin-bottom: 60px;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.darkBlue};
`

const ContentWrapper = styled.div`
  width: 75%;

  @media ${device.tablet} {
    width: 100%;
  }
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;

  td,
  th {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: left;
  }

  td:first-child {
    width: 100%;
  }

  th {
    color: ${({ theme }) => theme.colors.blue};
  }
`

export function Organizations() {
  const organizationsQuery = useQuery(
    gql(/* GraphQL */ `
      query allOrganizations {
        allOrganizations {
          id
          name
        }
      }
    `)
  )

  const organizations = organizationsQuery.data?.allOrganizations

  const { t } = useTranslation()

  return (
    <div>
      <Header>
        <TitleH2>{t('organizations.title')}</TitleH2>
        <SubTitle>
          {organizations !== undefined ? (
            t('organizations.subtitle', {
              count: organizations.length,
            })
          ) : (
            <>&nbsp;</>
          )}
        </SubTitle>
      </Header>
      <ContentContainer>
        <ContentWrapper>
          <Table>
            <thead>
              <tr>
                <th>{t('organizations.name')}</th>
                <th>{t('organizations.operations')}</th>
              </tr>
            </thead>
            <tbody>
              {organizations?.map((organization) => (
                <tr key={organization.id}>
                  <td>{organization.name}</td>
                  <td>
                    <></>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ContentWrapper>
      </ContentContainer>
    </div>
  )
}
